import React, { useState, useEffect } from "react";

import { ErrorBoundary } from "storybook-dashboard/errors";
import IssuesRAGPanel from "storybook-dashboard/dashboard/ragPanels/issues";
import IndicatorsRAGPanel from "storybook-dashboard/dashboard/ragPanels/indicators";
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar";
import InputValuesTable from "storybook-dashboard/dashboard/project/inputValues";
import IndicatorPerformanceTable from "storybook-dashboard/dashboard/performanceTable";
import DataExplorer from "storybook-dashboard/dashboard/components/dataExplorer";
import useApi from "storybook-dashboard/utils/fetching";
import Im from "immutable";

export default function DashboardComponents({ companyId, iconPath, downloadExcel, filters }) {
  let [selectedIssue, setSelectedIssue] = useState(null);
  let [selectedIndicatorsById, setSelectedIndicatorsById] = useState(Im.Map()); // A map of id: indicator data for selected indicators

  // Reset the selected indicators to none if the issue selection changes
  useEffect(() => {
    setSelectedIndicatorsById(Im.Map());
  }, [selectedIssue]);

  // Get a list of only the selected indicators
  let selectedIndicators = selectedIndicatorsById.toList();

  return (
    <ErrorBoundary>
      <ErrorBoundary>
        <IssuesRAGPanel
          companyId={companyId}
          iconPath={iconPath}
          selected={selectedIssue}
          setSelected={setSelectedIssue}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsRAGPanel
          companyId={companyId}
          iconPath={iconPath}
          issue={selectedIssue}
          selected={selectedIndicatorsById}
          setSelected={setSelectedIndicatorsById}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsBarChart
          companyId={companyId}
          indicators={selectedIndicators}
          downloadExcel={downloadExcel}
          filters={filters}
          period="year"
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <DataExplorer
          companyId={companyId}
          indicators={selectedIndicators}
          options={{
            // metric_values: {
            //   text: "Performance breakdown table",
            //   component: IndicatorPerformanceTable,
            // },
            performance: {
              text: "Input values table",
              component: InputValuesTable,
            },
          }}
        />
      </ErrorBoundary>
    </ErrorBoundary>
  );
}
