import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { BSCard } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { dataToState, getFetchBody, getDispatchFetches } from "./sustainabilityPanel/helpers";
import { chartBackgroundColors } from "AppSrc/dashboards/icons/utils";
import ChartJSWrapper from "AppSrc/charts/chartjs";
import { getChartData } from "./indicatorBarVertical";

import { latestPath } from "./sustainabilityPanel";
import TipsSpinner from "../utils/spinners/tipsSpinner";
import { getTargetFromList } from "./reportingParser";

const addSelectedIndicatorsToChart = (name, value, i = 0) => {
  return {
    label: [name],
    data:
      value < 0.01
        ? [value?.toFixed(5)]
        : value < 0.1
        ? [value?.toFixed(4)]
        : value < 1
        ? [value?.toFixed(3)]
        : [value?.toFixed(2)],
    backgroundColor: chartBackgroundColors[i]
  };
};

const addTargetLinesToChart = (name, value, color, i = 0) => {
  //console.log("addTargetLinesToChart", name, value, color, i);
  return {
    drawTime: "afterDraw",
    id: `a-line-${i}`,
    type: "line",
    mode: "vertical",
    scaleID: "x-axis-0",
    value: value,
    endValue: value,
    borderColor: `${color}`,
    borderWidth: 2,
    order: 2
    //  --- Label no longer used in annotation ---
    // label: {
    //   enabled: true,
    //   content: name == "target" ? "Target" : "Threshold",
    //   xValue: name === "Red/Amber Threshold" ? 5.5 : 7.5,
    //   position: name === "target" ? "center" : "top",
    //   backgroundColor: color
    // }
  };
};

const getCurrentTargetForDate = (target, _date) => {
  if (!target) {
    return null;
  }
  let _byDateData = target?.getInPath("meta_json.byDate");
  let _targetValue = getTargetFromList(_date, _byDateData, "target") || target.get("value");
  let _thresholdValue =
    getTargetFromList(_date, _byDateData, "threshold") || target.getIn(["meta_json", "thresholdLow"]);
  //console.log("getCurrentTargetForDate",_date, _targetValue, _thresholdValue, target?.toJS())
  return Traec.Im.Map({
    target: _targetValue,
    threshold: _thresholdValue ? parseFloat(_thresholdValue) : _thresholdValue
  });
};

const getMetricNameTargetMap = targets => {
  return (targets || Traec.Im.Map()).toList().reduce((acc, cur) => {
    let name = cur.getIn(["metric", "name"]);
    return acc.set(name, cur);
  }, Traec.Im.Map());
};

const refactorData = (data, targets, reportingPeriod) => {
  let chartDatasets = [];
  let chartAnnotation = [];
  //console.log("Inside refactorData", data?.toJS(), targets?.toJS(), data.get("datasets").size);

  for (let i = 0; i < (data?.get("datasets")?.size || 0); i++) {
    chartDatasets.push(
      addSelectedIndicatorsToChart(
        data?.getIn(["datasets", i, "label"]),
        data?.getIn(["datasets", i, "data"])?.last(),
        i
      )
    );
  }

  if ((data?.get("datasets") || Traec.Im.List()).size == 1) {
    //console.log("refactorData. Setting targets as chart annotation", targetMap?.toJS());
    let label = data?.getIn(["datasets", 0, "label"]);
    let targetMap = getMetricNameTargetMap(targets);
    let target = targetMap.get(label);
    //console.log("refactorData has target", targetData?.toJS());
    let targetData = getCurrentTargetForDate(target, reportingPeriod?.get("endDate"));
    targetData?.forEach((value, key) => {
      //console.log("refactorData setting annotation for ", key, value);
      if (value !== undefined && value !== null && key !== undefined) {
        chartAnnotation.push(
          addTargetLinesToChart(key, value, key == "target" ? "green" : "red", key == "target" ? 0 : 1)
        );
        //console.log("KEYHERE", key, value);
        chartDatasets.push({
          type: "line",
          label: key == "target" ? `Target: ${value} ` : `Threshold: ${value}`,
          backgroundColor: key === "target" ? "rgb(54, 170, 71)" : "red"
        });
      }
    });
  }

  return { chartDatasets, chartAnnotation };
};

function CumulativeHorizontalChartWrapper({ data, targets, reportingPeriod }) {
  const [selectedData, setSelectedData] = useState([]);
  if (!data) return null;

  let { chartDatasets, chartAnnotation } = refactorData(data, targets, reportingPeriod);

  //console.log("horizontal chart dataset ==> ", chartDatasets, "::", chartAnnotation);

  const roundDecimals = (label, index, labels) => {
    if (label >= 1000) {
      //Adding commas to thousands
      let commaLabel = label.toFixed();
      commaLabel = commaLabel.toString();
      commaLabel = commaLabel.split(/(?=(?:...)*$)/);
      commaLabel = commaLabel.join(",");
      return commaLabel;
    }
    return label == 0
      ? label
      : label < 0.001
      ? label.toFixed(5)
      : label < 0.01
      ? label.toFixed(4)
      : label < 0.1
      ? label.toFixed(3)
      : label < 1000
      ? label.toFixed(2)
      : label.toFixed(0);
  };

  const tooltipRounding = (tooltipItem, data) => {
    let label = data.datasets[tooltipItem.datasetIndex].label || "";

    if (label) {
      label += ": ";
    }

    label += roundDecimals(tooltipItem.xLabel);
    return label;
  };

  const chartData = {
    type: "horizontalBar",
    options: {
      indexAxes: "y",
      barThickness: 50,
      height: 150,
      elements: {
        bar: {
          borderWidth: 2
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              userCallback: roundDecimals

              //max: 95
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true
              //max: 95
            }
          }
        ]
      },
      annotation: { annotations: chartAnnotation },
      tooltips: {
        mode: "nearest",
        axis: "y",
        callbacks: {
          label: tooltipRounding
        }
      }
    },
    data: {
      labels: [""],
      datasets: chartDatasets
    }
  };
  //console.log("horizontal datasetInsideChart", chartData.data.datasets);
  let horizontalChartHeight = chartData.data.datasets.length + 3 * 100;

  return (
    <ErrorBoundary>
      <div style={{ height: "200px" }}>
        <ChartJSWrapper chartData={chartData} />{" "}
      </div>
    </ErrorBoundary>
  );
}

const getPreDispatchHook = props => {
  return action => {
    action.fetchParams.body = props.fetchBody;
    action.fetchParams.headers = { "content-type": "application/json" };
    action.fetchParams.rawBody = false;
    action.fetchParams.throttleTimeCheck = 1000 * 3600; // Throttle request to every hour (to prevent calling backend every click)
    action.stateParams.stateSetFunc = (state, data) => {
      //console.log("Got INDICATOR_DETAIL for horizontal bar chart", data);
      return dataToState(props, state, data);
    };
    //console.log("Calling dispatch for INDICATOR_DETAIL data", action);
    return action;
  };
};

export const IndicatorHorizonalBar = props => {
  let [indicatorChartData, setIndicatorChartData] = useState(null);
  let [state, setState] = useState({ fetchedUrls: {} });

  let {
    selected,
    indicator,
    data,
    fromDate,
    toDate,
    targets,
    category,
    query_params,
    query_string,
    //cumulation,
    setCumulation,
    projectId,
    refId,
    companyId,
    isRootRef,
    filters,
    latestId
  } = props;

  //console.log("Rendering indicator horizontal bar chart", targets?.toJS()); //data?.toJS()

  let cumulation = "total";

  useEffect(() => {
    //Looks through props and ensures all required props are present (eg. companyId must be in props when requesting for it)
    Traec.fetchRequiredFor({
      props,
      state,
      setState,
      requiredFetches: getDispatchFetches(props, getPreDispatchHook(props))
    });
  }, [companyId, refId, query_string]);

  useEffect(() => {
    let indicatorNames = selected?.map(data => data.get("name")).toList();

    // Get the data
    let _data = Traec.Im.fromJS([data.get(latestId)]);

    let chartData = getChartData(_data, targets, cumulation, category, indicatorNames) || Traec.Im.Map();
    //console.log("Setting indicator chart data in IndicatorHorizontalChart", chartData?.toJS());

    setIndicatorChartData(chartData);
  }, [query_params, selected, category, data]);

  if (!indicatorChartData || !indicatorChartData.size) {
    return <TipsSpinner message="Loading indicator graphs" />;
  }

  let indicatorId = indicator?.get("_key");
  let indicatorName = indicator?.get("name");

  let title = indicatorName || `All Indicators for ${category}`;
  //title = title + ` (${cumulation == "current" ? "per period" : "cumulative"})`;

  //console.log("Rendering IndicatorHorizonalChart with data", indicatorChartData?.toJS());

  return (
    <ErrorBoundary>
      <div className="row">
        <ErrorBoundary>
          <BSCard
            widthOffset="col-sm-12"
            title={`Cumulative data`}
            // button={<CumulativeButton cumulation={cumulation} setCumulation={setCumulation} />}
            body={
              <React.Fragment>
                <CumulativeHorizontalChartWrapper
                  data={indicatorChartData}
                  targets={targets}
                  reportingPeriod={data?.get(latestId)}
                />
              </React.Fragment>
            }
          />
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { companyId, projectId, hostId, selected, refId } = ownProps;

  hostId = hostId || companyId || projectId;
  let hostType = companyId ? "company" : "project";

  // Get the selected category
  let category_id = selected?.first()?.get("category_id");
  let category = selected?.first()?.get("category");

  let commitId = state.getInPath(`entities.refs.byId.${refId}.latest_commit.uid`);
  let indicators = null;
  if (hostId === companyId) {
    indicators = state.getInPath(`entities.companyObjects.byId.${hostId}.indicators`);
  } else {
    indicators = state.getInPath(`entities.commitEdges.byId.${commitId}.indicators`);
  }

  // Get the indicator details
  let indicator = selected?.size === 1 ? selected.first() : null;
  indicator = indicator ? indicator.set("uid", indicator.get("_key")) : null;
  indicator =
    indicators && indicator
      ? indicator.set("baseMetric", indicators.getInPath(`${indicator.get("uid")}.resultBaseMetric.uid`))
      : indicator;

  // Add the body of our API data call to the props (so we can get it in the requiredFetches above)
  let { fetchBody, filterHash, queryParams: query_params } = getFetchBody(
    { ...ownProps, cumulation: "total", only_latest: "true" },
    "INDICATOR_DATA"
  );
  let query_string = new URLSearchParams(query_params).toString();

  // Get the latest
  let latestId = state.getInPath(`entities.${latestPath(ownProps)}`);

  return { hostId, category, indicator, category_id, fetchBody, query_params, query_string, filterHash, latestId };
};

export default connect(mapStateToProps)(IndicatorHorizonalBar);
