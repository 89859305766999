import React from "react";

export function CompanyWarningMessage() {
  return (
    <div className="alert alert-warning text-center">
      <strong>
        What’s coming next? We plan to have an industry dashboard available to all members to display anonymised
        industry wide carbon data. In the meantime, please click on your company name in the left menu to see a
        dashboard of your carbon data.
      </strong>
    </div>
  );
}
