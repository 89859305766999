import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";
import { BSCard } from "traec-react/utils/bootstrap";
import { DateString, dateString } from "AppSrc/project/components";
import { colorScale } from "AppSrc/dashboards/utils";
import { Spinner } from "traec-react/utils/entities";
import { dataToState, getFetchBody } from "../sustainabilityPanel/helpers";
import { TitleTooltip, getRefsFromReportingPeriods } from "./utils";
import Moment from "moment";
import Octicon from "react-octicon";
import Papa from "papaparse";

const getStatus = ({ projectId, reportPeriod, refId }) => {
  let result = reportPeriod.getInPath(`STATUS_BY_REF.${refId}`);
  let status = result ? result.getInPath("status.name") : null;
  let _status = null;
  if (status == null) {
    status = result ? "Nothing Received" : "No Report";
    _status = <Octicon name="dash" />;
  } else if (status.startsWith("Not for")) {
    status = "On Hold";
    _status = <Octicon name="watch" />;
  } else if (status.startsWith("OK")) {
    status = "Approved";
    _status = <Octicon name="check" />;
  }
  let content = result ? (
    <Link to={`/project/${projectId}/wpack/${refId}/evals/`}>{_status}</Link>
  ) : (
    <Octicon name="dash" />
  );
  return { content, status, result };
};

const getColorFromStatus = status => {
  let colorValue = null;
  switch (status) {
    case "Nothing Received":
      colorValue = null;
      break;
    case "Approved":
      colorValue = 1;
      break;
    case "Pending Approval":
      colorValue = 0.5;
      break;
    case "On Hold":
      return "#c2c2c2";
    case "Requires Revision":
      return "#acd2ff";
    default:
      colorValue = null;
  }
  return colorScale(colorValue)
    .brighten(2)
    .hex();
};

function ReportCommitCell(props) {
  let { projectId, refId } = props;
  let [redirectTo, setRedirectTo] = useState(null);

  // Do any pending redirection
  let redirectUrl = `/project/${projectId?.substring(0, 8)}/wpack/${refId?.substring(0, 8)}/`;
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  let { content, status } = getStatus(props);

  let styleObj = {
    backgroundColor: getColorFromStatus(status),
    cursor: "pointer",
    border: "1px solid gray"
  };

  return (
    <div className="col-sm text-center" style={styleObj} onClick={() => setRedirectTo(redirectUrl)}>
      {content}
    </div>
  );
}

export const ReportCommitRow = function({ projectId, cref, selectedReportingPeriods }) {
  if (!cref) {
    return null;
  }
  let cells = selectedReportingPeriods
    ? selectedReportingPeriods
        .toList()
        .map((reportPeriod, i) => (
          <ReportCommitCell key={i} projectId={projectId} refId={cref.get("uid")} reportPeriod={reportPeriod} />
        ))
    : null;

  return (
    <div className="row">
      <div className="col-sm-3">
        <Link to={`/project/${projectId.substring(0, 8)}/wpack/${cref.get("uid").substring(0, 8)}/`}>
          {cref.get("name")}
        </Link>
      </div>
      {cells}
    </div>
  );
};

export const ReportHeaderRow = ({ reportingPeriods }) => {
  let cells = reportingPeriods
    ? reportingPeriods.toList().map((rp, i) => (
        <div className="col text-center" key={i}>
          <b>
            <DateString obj={rp} field={"endDate"} add={-1} format="MMM YY" />
          </b>
        </div>
      ))
    : null;

  return (
    <div className="row">
      <div className="col-sm-3">
        <b>Period Starting</b>
      </div>
      {cells}
    </div>
  );
};

const getPreDispatchHook = props => {
  let { fetchBody } = props;
  return action => {
    action.fetchParams.body = fetchBody;
    action.fetchParams.headers = { "content-type": "application/json" };
    action.fetchParams.rawBody = false;
    action.fetchParams.throttleTimeCheck = 1000 * 3600; // Throttle request to every hour (to prevent calling backend every click)
    action.stateParams.stateSetFunc = (state, data) => {
      //console.log("Loaded COMMIT_STATUS data", data);
      return dataToState(props, state, data);
    };
    //console.log("Calling tracker_dispatch for COMMIT_STATUS data", action);
    return action;
  };
};

function ProjectReportTableBody(props) {
  let { projectId, categoryName, indicator, selectedReportingPeriods, refs } = props;

  // Map redux values to props, and sort results by name for reporting packages
  const rows = refs
    .toList()
    .filter(cref => cref) // Ensure that all the refs are populated (no nulls)
    .sortBy(cref => cref.get("name"))
    .map((cref, i) => {
      return (
        <ReportCommitRow
          key={i}
          projectId={projectId}
          cref={cref}
          selectedReportingPeriods={selectedReportingPeriods}
        />
      );
    });

  if (rows.size) {
    let isListEmpty = rows.every(elem => !elem);
    if (isListEmpty && categoryName && indicator) {
      return (
        <React.Fragment>
          <p>Indicator is computed from different Reporting Packages. Performance table can not be shown.</p>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ReportHeaderRow reportingPeriods={selectedReportingPeriods} />
        {rows}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Spinner title="Loading Data..." timedOutComment="Insufficient data to generate table" />
      </React.Fragment>
    );
  }
}

const csvReportHeaderRow = reportingPeriods => {
  return (reportingPeriods || Traec.Im.Map())
    .toList()
    .map((rp, i) => dateString(rp, "endDate", -1, "", "MMM YY"))
    .unshift("Period Starting");
};

const csvReportCell = props => {
  let { projectId, refId } = props;
  let { content, status } = getStatus(props);
  return status || "null";
};

const csvReportRow = ({ projectId, cref, selectedReportingPeriods }) => {
  return (selectedReportingPeriods || Traec.Im.Map())
    .toList()
    .map((reportPeriod, i) => csvReportCell({ projectId, refId: cref.get("uid"), reportPeriod }))
    .unshift(cref.get("name"));
};

const downloadStatusCSV = props => {
  let { projectId, selectedReportingPeriods, refs } = props;
  //console.log("Generating status of reporting packages CSV", selectedReportingPeriods);

  let rows = refs
    .toList()
    .filter(cref => cref) // Ensure that all the refs are populated (no nulls)
    .sortBy(cref => cref.get("name"))
    .map((cref, i) => csvReportRow({ projectId, cref, selectedReportingPeriods }))
    .unshift(csvReportHeaderRow(selectedReportingPeriods));

  //console.log("Got report status csv data", rows?.toJS());

  let csvContent = Papa.unparse(rows?.toJS());

  let csvBlob = new Blob([csvContent], { type: "text/csv" });
  let blobUrl = window.URL.createObjectURL(csvBlob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = blobUrl;
  link.download = `report_statuses_${projectId}.csv`;
  document.body.appendChild(link);
  link.click();
};

function ProjectReportCommitResults(props) {
  let { hide, selectedReportingPeriods, projectRefs } = props;
  //let [refs, setRefs] = useState(Im.Set())

  if (hide || !selectedReportingPeriods) {
    return null;
  }

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [
        new Traec.Fetch("tracker_dispatch", "post", {}, { preDispatchHook: getPreDispatchHook(props) }),
        new Traec.Fetch("tracker_commit_branch", "list")
      ]
    });
  }, []);

  return (
    <BSCard
      widthOffset="col-sm-12"
      title={
        <React.Fragment>
          <span>{`Status of reporting packages`}</span>
          <TitleTooltip
            text={
              <p>
                This table indicates whether data has been submitted, rejected or approved for each reporting package.
              </p>
            }
          />
        </React.Fragment>
      }
      button={
        <span
          className="float-right"
          style={{ cursor: "pointer" }}
          onClick={() => downloadStatusCSV({ ...props, refs: projectRefs })}
        >
          <Octicon name="cloud-download" />
        </span>
      }
      body={
        <ProjectReportTableBody
          {...props}
          refs={projectRefs} //getRefsFromReportingPeriods(props)
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  let { selectedReportingPeriods, latestReportingPeriod: latestRP, projectId } = ownProps;

  // Get the duration of a single (latest) reporting period
  let rpTime = latestRP ? new Moment(latestRP.get("endDate")) - new Moment(latestRP.get("startDate")) : null;

  let { fetchBody, filterHash, queryParams: query_params } = getFetchBody(
    {
      ...ownProps,
      filters: Traec.Im.Map(),
      fromDate: rpTime ? Moment().add(-13 * rpTime) : Moment().add(-13, "months"),
      toDate: Moment()
    },
    "PROJECT_REPORT_COMMIT_STATUS"
  );

  // For each reporting period turn the commit_results into a map by refId
  if (selectedReportingPeriods) {
    selectedReportingPeriods = selectedReportingPeriods.map(rp => {
      let commitResults = rp.getInPath(`PROJECT_REPORT_COMMIT_STATUS`);
      let refResults = commitResults
        ? commitResults.mapEntries(([commitId, result]) => {
            return [result.getInPath("ref.uid"), result];
          })
        : null;
      return rp.set("STATUS_BY_REF", refResults);
    });
  }

  // Get all of the refs for this project
  let projectRefs = state
    .getInPath(`entities.refs.byId`)
    ?.toList()
    ?.filter(i => i?.get("project")?.startsWith(projectId))
    ?.filter(i => i.get("name") !== "master");

  return {
    fetchBody,
    query_params,
    filterHash,
    selectedReportingPeriods,
    projectRefs
  };
};

export default connect(mapStateToProps)(ProjectReportCommitResults);
