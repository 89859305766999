import "./spinnerStyles.css";
import { sustainabilityTips } from "./sustainabilityTips";
import React, { useState, useEffect } from "react";

function TipsSpinner({ message }) {
  const [timedout, setTimedout] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimedout(true);
    }, 13000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  let randomTip = getRandomInt(sustainabilityTips.length);
  let tipAtRuntime = randomTip;

  return timedout ? (
    <div className="alert alert-warning text center">No data available. Please try again later.</div>
  ) : (
    <div className="tip-spinner col">
      <div className="spinner ">
        <div className="spinner-sector spinner-sector-top"></div>
        <div className="spinner-sector spinner-sector-left"></div>
        <div className="spinner-sector spinner-sector-right"></div>
        <div className="spinner-children ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-bar-chart-line-fill"
            viewBox="0 0 16 16"
          >
            <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" />
          </svg>
          <p style={{ fontSize: "1.3rem" }}> Loading...</p>
        </div>
      </div>
      <div className="tips">{message ? <p>{message}</p> : <></>}</div>
    </div>
  );
}

export default TipsSpinner;
