import React, { useState } from "react";
import { BSBtn, BSBtnDropdown, BSModal } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors";
import ReportRowErrorBoundary from "./error";
import { DocumentTitleTooltip, CurrentObject } from "./reportDocumentRow";
import Traec from "traec";
import Papa from "papaparse";
import { alertSuccess, confirmDelete } from "traec-react/utils/sweetalert";
import Moment from "moment";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";
import logger from "redux-logger";

const fetchS3Object = ({ trackerId, commitId, docId, currentDocObject, responseHandler, failureHandler }) => {
  let _fetch = new Traec.Fetch("tracker_commit_document_object", "retrieve", {
    trackerId,
    commitId,
    docId,
    docObjectId: currentDocObject.get("uid"),
    signedURL: true
  });
  _fetch.updateFetchParams({
    throttleTimeCheck: 1,
    cacheTime: 1,
    postSuccessHook: data => {
      if (data.signed_url) {
        fetch(data.signed_url, { cache: "no-cache" })
          .then(responseHandler)
          .catch(err => {
            console.warn("ERROR PARSING S3 DATA", err);
          });
      }
    },
    postFailureHook: err => {
      failureHandler ? failureHandler(err) : null;
    }
  });
  _fetch.dispatch();
};

function LoadDataButton(props) {
  let { title, hide } = props;
  if (hide) {
    return null;
  }
  return (
    <button
      className="btn btn-sm btn-warning pt-0 pb-0 ml-1 mr-1 float-right"
      onClick={e => {
        e.preventDefault();
        fetchS3Object(props);
      }}
    >
      {title}
    </button>
  );
}

export function TableRow({ fields, data, copyHandler, updateHandler, removeHandler, readOnly }) {
  //console.log("Rendering table row", data?.toJS());

  let [edit, setEdit] = useState(data.has("_errors"));
  let [_data, setData] = useState(data);

  let cells = fields.map((field, i) => (
    <td key={i} className="border-0">
      {data.get(field.get("header"))}
    </td>
  ));

  if (edit) {
    return (
      <AddRowForm
        fields={fields}
        values={_data}
        addHandler={e => {
          updateHandler(_data);
          setEdit(false);
        }}
        onChangeHandler={e => {
          setData(_data.merge({ [e.target.name]: e.target.value }));
        }}
      />
    );
  }

  return (
    <ReportRowErrorBoundary>
      <tr>
        {cells}
        {readOnly ? null : (
          <td className="text-center border-0">
            <BSBtnDropdown
              header={" "}
              floatStyle={" "}
              links={[
                { name: "Copy", onClick: e => copyHandler(data) },
                {
                  name: "Edit",
                  onClick: e => {
                    setData(data);
                    setEdit(true);
                  }
                },
                { name: "Delete", onClick: removeHandler }
              ]}
            />
          </td>
        )}
      </tr>
    </ReportRowErrorBoundary>
  );
}

const Header = props => {
  let { field } = props;
  let header = field.get("header");
  let description = field.get("description");

  if (description) {
    return (
      <th>
        {header}{" "}
        <Tooltip html={description} animateFill={false} className={"text-left"}>
          <Octicon name="info" />
        </Tooltip>
      </th>
    );
  }

  return <th>{header}</th>;
};

export function TableHeaders({ fields, readOnly }) {
  let headers = fields.map((field, i) => <Header key={i} field={field} />);
  return (
    <tr>
      {headers}
      {readOnly ? null : (
        <td className="text-center border-0">
          <Octicon name="gear" />
        </td>
      )}
    </tr>
  );
}

function FormCellDropdown(props) {
  let { field, values, errors, onChangeHandler } = props;
  let header = field.get("header");
  let options = field.get("options") || Traec.Im.List();
  let error = errors.get(header);
  let value = values.get(header);

  let _options = options.unshift(null).map((option, i) => (
    <option key={i} value={option}>
      {option}
    </option>
  ));

  return (
    <td className="border-0">
      <select
        type="text"
        className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
        id={header}
        name={header}
        value={value}
        onChange={onChangeHandler}
      >
        {_options}
      </select>
    </td>
  );
}

function FormCellInput(props) {
  let { field, values, errors, onChangeHandler } = props;
  let header = field.get("header");
  let fieldType = field.get("type");
  let error = errors.get(header);
  let value = values.get(header);

  if (fieldType === "selection") {
    return <FormCellDropdown {...props} />;
  }

  return (
    <td className="border-0">
      <input
        type="text"
        className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
        id={header}
        name={header}
        value={value}
        onChange={onChangeHandler}
      />
    </td>
  );
}

const isNumber = value => {
  try {
    let _value = +value;
    return typeof _value === "number" && isFinite(_value);
  } catch {
    return false;
  }
};

const isPostcode = value => {
  if (!(typeof value === "string")) {
    return false;
  }
  if (!value.includes(" ")) {
    return false;
  }
  let parts = value.split(" ");
  if (!(parts.length === 2)) {
    return false;
  }
  if (!(parts[0].length >= 2 && parts[0].length <= 4)) {
    return false;
  }
  if (!(parts[1].length == 3)) {
    return false;
  }
  return true;
};

const isDate = value => {
  let date = Moment(value, "DD/MM/YYYY");
  return Moment.isMoment(date) && date.isValid() ? true : false;
};

const validSelection = (value, field) => {
  return Traec.Im.Set(field?.get("options") || []).has(value);
};

const validatorMap = {
  string: {
    func: null,
    message: "Value must be a string"
  },
  number: {
    func: isNumber,
    message: "Value must be a finite number"
  },
  postcode: {
    func: isPostcode,
    message: "Value must be a postcode with format PP YYY where the prefix PP is between 2 and 4 characters"
  },
  freetext: {
    func: null,
    message: "Value must be a string"
  },
  selection: {
    func: validSelection,
    message: "Value must be one of the choices available"
  },
  date: {
    func: isDate,
    message: "Value must be a date in the format dd/mm/yyyy"
  }
};

const validateFields = (fields, values) => {
  //console.log("Validating fields for data", fields, values);
  let errors = Traec.Im.Map();
  for (let field of fields) {
    let header = field.get("header");
    let value = values.get(header);
    let fieldType = field.get("type");
    //console.log("Validating field", header, fieldType, value);
    let validator = validatorMap[fieldType];
    if (!validator || !validator.func) {
      continue;
    }
    if (!validator.func(value, field)) {
      errors = errors.set(header, validator.message);
    }
  }
  if (errors.size) {
    //console.log("Got validation errors", errors.toJS(), "for data", values?.toJS());
  } else {
    //console.log("Validation returned no errors");
  }
  return errors;
};

export function AddRowForm({ fields, values, addHandler, onChangeHandler }) {
  if (!fields) {
    return null;
  }
  let [errors, setErrors] = useState(values?.get("_errors") || Traec.Im.Map());

  let inputs = fields.map((field, i) => (
    <FormCellInput key={i} field={field} values={values} onChangeHandler={onChangeHandler} errors={errors} />
  ));

  let errorMessages = errors.keySeq().map((header, i) => (
    <p key={i} className="text-danger m-0 p-0">
      <b>{header}</b>: {errors.get(header)}
    </p>
  ));

  return (
    <ErrorBoundary>
      <tr style={{ marginTop: "1px solid black" }}>
        {inputs}
        <td className="text-center border-0">
          <a
            onClick={e => {
              let _errors = validateFields(fields, values);
              //console.log("VALDIATED FIELDS", _errors ? _errors.toJS() : null);
              if (!_errors?.size) {
                addHandler(e);
                setErrors(Traec.Im.Map());
              } else {
                setErrors(_errors);
              }
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-sm btn-primary mb-1 pb-0 mt-1 pt-0"
          >
            Add
          </a>
        </td>
      </tr>
      {errors.size ? (
        <tr>
          <td colSpan={100} className="border-0">
            {errorMessages}
          </td>
        </tr>
      ) : null}
    </ErrorBoundary>
  );
}

const uploadFile = (formData, props) => {
  /* You can send this as a raw file if you set:
    headers: { "content-type": null: "content-disposition": "attachment; filename=upload.jpg" },
    body: this.state.selectedFiles[0]
    */
  let { trackerId, commitId, refId, path: pathId, setPending } = props;
  let fetch = new Traec.Fetch("tracker_node", "put", {
    trackerId,
    commitId,
    refId,
    pathId,
    allow_commit_change: true
  });
  fetch.updateFetchParams({
    headers: { "content-type": null },
    rawBody: true,
    body: formData,
    preFetchHook: data => {
      setPending ? setPending(true) : null;
      return data;
    },
    postSuccessHook: () => {
      setPending ? setPending(false) : null;
    }
  });
  fetch.dispatch();
};

const dataToCSVText = ({ fields, data }) => {
  let headers = fields.map(field => field.get("header"));
  let lines = [];
  lines.push(headers.join(","));
  data.map(row => {
    lines.push(headers.map(header => row.get(header) || "").join(","));
  });
  return lines.join("\n");
};

const textToFormData = ({ text, path, filename = "data.csv" }) => {
  let formData = new FormData();
  const blob = new Blob([text], { type: "text/csv" });
  formData.append("fileobj", blob, filename);
  formData.append("type", "document");
  formData.append("path", path);
  //console.log("Packaged csv text as blob in multipart file object", text);
  return formData;
};

export const textToDataArray = text => {
  let results = Papa.parse(text, { header: true });
  return results.data;
};

const parseCSVData = (e, data, setData, fields) => {
  e.preventDefault();
  $("#file-selector").on("change", e => {
    //console.log("Selected files", e.target.files);
    //console.log("Parsing CSV file");
    Papa.parse(e.target.files[0], {
      complete: results => {
        let headers = results.data[0];
        //console.log("FOUND CSV HEADERS", headers)
        let newData = results.data
          .slice(1)
          .map(row => headers.reduce((acc, cur, i) => Object.assign(acc, { [cur]: row[i] }), {}));
        // RUN VALIDATION ON EACH ROW HERE - AND RE-ORDER SO THAT ROWS WITH ERRORS ARE AT THE TOP
        let _newData = Traec.Im.fromJS(newData)
          .filter(item => !item.toList().every(i => i == "" || i == null)) // Filter out empty rows
          .map(item => {
            let _errors = validateFields(fields, item);
            return _errors?.size ? item.set("_errors", _errors) : item;
          })
          .sortBy(i => !i.has("_errors"));
        //console.log("GOT NEW CSV DATA", _newData?.toJS());
        //console.log("CONCAT with existing data", data?.toJS());
        setData(data.concat(_newData));
      }
    });
  });
  $("#file-selector").trigger("click");
};

function DataTable({ fields, formName, data, setData, trackerId, commitId, path, readOnly }) {
  const [pending, setPending] = useState(false);

  let initNewData = fields.reduce((acc, cur) => acc.set(cur.get("header"), ""), Traec.Im.Map());
  let [newData, setNewData] = useState(initNewData);

  let filename = `${formName}.csv`;

  let rows = data.map((d, i) => (
    <TableRow
      key={i}
      index={i}
      fields={fields}
      data={d}
      setData={setData}
      readOnly={readOnly}
      removeHandler={e => {
        confirmDelete({
          text: `Are you sure you want to remove this row?`,
          onConfirm: () => {
            setData(data.delete(i));
          }
        });
      }}
      updateHandler={rowData => {
        setData(data.set(i, rowData));
      }}
      copyHandler={rowData => {
        setData(data.insert(i, rowData));
      }}
    />
  ));

  // Work out if we should disable the save button
  //let disableSave = !data.size || (data || Traec.Im.List()).some(i => (i.get("_errors") || Traec.Im.Map()).size)
  let disableSave = false;

  let templateFiles = {
    "Waste log": {
      href: "/assets/files/SBCC-waste-log.xlsx",
      download: "SBCC-Waste-log.xlsx"
    },
    "Top 5 categories of spend": {
      href: "/assets/files/SBCC-materials-log.xlsx",
      download: "SBCC-Materials-log.xlsx"
    }
  };
  let template = templateFiles[formName] || {};

  return (
    <ErrorBoundary>
      {readOnly ? null : (
        <ErrorBoundary>
          <button
            className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2"
            onClick={e => parseCSVData(e, data, setData, fields)}
          >
            Upload from CSV
          </button>
          <a
            className="btn btn-sm btn-warning pt-0 pb-0 ml-1 mr-1 mb-2"
            href={template.href}
            download={template.download}
          >
            Download Template
          </a>
          <button className="btn btn-sm btn-danger pt-0 pb-0 ml-1 mr-1 mb-2" onClick={e => setData(Traec.Im.List())}>
            Clear all data
          </button>
          <input style={{ display: "none" }} type="file" id="file-selector" />
          <button
            className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2 float-right"
            disabled={disableSave}
            onClick={e => {
              e.preventDefault();
              if (pending) {
                return null;
              }
              let text = dataToCSVText({ fields, data });
              //console.log("Uploading text data:");
              console.log(text);
              uploadFile(textToFormData({ text, path, filename }), { trackerId, commitId, path, setPending });
            }}
          >
            {pending ? <div className="spinner-sm" /> : "Save"}
          </button>
        </ErrorBoundary>
      )}
      <div style={{ clear: "both" }} />

      <div className="scrollTopContainer m-0 p-0" style={{ overflowX: "auto" }}>
        <table width="100%" className="table table-sm scrollTopContent m-0 p-0 table-hover">
          <thead>
            <TableHeaders fields={fields} readOnly={readOnly} />
          </thead>
          <tbody>
            {readOnly ? null : (
              <AddRowForm
                fields={fields}
                values={newData}
                onChangeHandler={e => {
                  setNewData(newData.merge({ [e.target.name]: e.target.value }));
                }}
                addHandler={e => {
                  setData(data.unshift(newData));
                  setNewData(initNewData);
                }}
              />
            )}
            {rows}
          </tbody>
        </table>
      </div>
    </ErrorBoundary>
  );
}

export function LoadOrEditDataButton(props) {
  let { data, setData, doc, modalId, currentDocObject, readOnly } = props;

  let docId = doc.get("uid");
  let current_url = currentDocObject ? currentDocObject.get("url") : null;

  let hasData = Traec.Im.isList(data) ? data.size > 0 : false;

  if (!hasData && current_url) {
    return (
      <LoadDataButton
        {...props}
        title={readOnly ? "View data" : "Edit data"}
        docId={docId}
        currentDocObject={currentDocObject}
        responseHandler={response => {
          if (!response.ok) {
            console.warn(`Response not ok (got status ${response.status})`);
            $(`#${modalId}`).modal("show");
            return;
          }
          return response
            .text()
            .then(_text => {
              setData(Traec.Im.fromJS(textToDataArray(_text)));
              $(`#${modalId}`).modal("show");
            })
            .catch(e => {
              console.warn("ERROR LOADING DATA");
            });
        }}
        failureHandler={error => {
          alertSuccess({
            title: "Error loading data",
            text:
              "There was an issue loading data for this form.  You will be presented with a new blank form to complete.  If you have issues or need to retrieve this data then please contact support."
          });
          $(`#${modalId}`).modal("show");
        }}
      />
    );
  }

  let action = readOnly ? "View" : hasData ? "Edit" : "Input";
  return (
    <BSBtn
      text={`${action} data`}
      onClick={e => $(`#${modalId}`).modal("show")}
      extra_className="pl-1 pr-1 m-0 p-0 float-right"
      noFloatRight={true}
    />
  );
}

export function ReportDocumentFormButton(props) {
  let { trackerId, commitId, path, doc, rowColor, indentLevel, readOnly } = props;
  if (!doc) {
    return null;
  }

  let [data, setData] = useState(Traec.Im.List());
  //console.log("Have data", data?.toJS());

  let formDetails = doc.getInPath("meta_json.input_details") || Traec.Im.Map();
  let formFields = formDetails.get("fields");

  let modalId = `${doc.get("uid")}-form`;
  let modalTitle = formDetails.get("name");

  return (
    <ReportRowErrorBoundary>
      <tr style={{ backgroundColor: rowColor }}>
        <td className="border-0">
          <DocumentTitleTooltip doc={doc} indentLevel={indentLevel} />
        </td>

        <td colSpan={6} className="border-0">
          <CurrentObject {...props} docId={doc.get("uid")} />

          <span className="float-right">&nbsp; | &nbsp;</span>
          <LoadOrEditDataButton {...props} data={data} setData={setData} modalId={modalId} />

          <BSModal
            id={modalId}
            title={modalTitle}
            fullWidth={true}
            body={
              <DataTable
                readOnly={readOnly}
                fields={formFields}
                formName={modalTitle}
                data={data}
                setData={setData}
                trackerId={trackerId}
                commitId={commitId}
                path={path}
              />
            }
          />
        </td>
      </tr>
    </ReportRowErrorBoundary>
  );
}
