import React from "react";
import VERSION from "AppAssets/text/VERSION.txt";
import BUILD_DATE from "AppAssets/text/BUILD_DATE.txt";

export const Footer = function() {
  return (
    <div className="footer">
      <div className="footer-inner row">
        <div className="col-sm">
          2023 © Action Sustainability |
          <a href="info@sustainabilitytool.com" target="_blank">
            info@sustainabilitytool.com
          </a>
        </div>
        <div className="col-sm text-right" style={{ color: "#2081C5" }}>
          {VERSION.slice(0, 8)} {BUILD_DATE}
        </div>
      </div>
    </div>
  );
};
