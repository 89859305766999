import React from "react";
import { connect } from "react-redux";
import Traec from "traec";

import BaseFormConnected from "traec-react/utils/form";

import { projectFields } from "AppSrc/project/form";

export const setFetchBody = (post, company) => {
  let styles = (company.getInPath("meta_json.styles") || Traec.Im.Map()).toJS();

  post.company = { uid: company.get("uid") };
  post.meta_json = { styles: styles };

  let [templateTrackerId, templateProjectId] = (post.from_template || "").split(".");
  post.from_template = templateTrackerId || null;

  if (!post.reporting_from_template) {
    post.reporting = {
      startDate: post.reporting_start,
      endDate: post.reporting_end,
      freq_unit: post.reporting_units,
      freq_num: post.reporting_freq
    };
  } else {
    post.meta_json.setup_from_project = templateProjectId;
  }

  //console.log("SENDING POST DATA", post);
  return post;
};

class CompanyProjectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formFields: projectFields,
      setTemplateFields: false
    };

    // Data required from the API for this Component
    this.requiredFetches = [
      new Traec.Fetch(
        "tracker",
        "list",
        {},
        {
          preUpdateHook: args => ({ ...args, onlyTemplates: true })
        }
      )
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    let { templates } = this.props;
    if (templates && !this.state.setTemplateFields) {
      this.setFormTemplates();
    }
  }

  setFormTemplates() {
    let { templates } = this.props;
    let templateOptions = templates
      .toList()
      .map((tracker, i) => (
        <option key={i} value={`${tracker.get("uid")}.${tracker.getInPath("project.uid")}`}>
          {tracker.getInPath("project.name")}
        </option>
      ))
      .unshift(
        <option key={-1} value={""}>
          None
        </option>
      );

    projectFields.from_template.options = templateOptions;

    this.setState({
      setTemplateFields: true
    });
  }

  setReportingDisabled(status) {
    projectFields.reporting_start.disabled = status;
    projectFields.reporting_end.disabled = status;
    projectFields.reporting_freq.disabled = status;
    projectFields.reporting_units.disabled = status;
  }

  render() {
    let { company, companyId, modalId } = this.props;

    let fetch = new Traec.Fetch("project_and_setup", "post");
    fetch.updateFetchParams({
      preFetchHook: body => setFetchBody(body, company),
      postSuccessHook: () => {
        $(`#${modalId}`).modal("hide");
        location.reload();
      }
    });

    return (
      <BaseFormConnected
        list="autocompleteOff"
        params={fetch.params}
        fields={projectFields}
        forceShowForm={true}
        hideUnderline={true}
        postChangeHook={e => {
          if (e.target.name == "reporting_from_template") {
            this.setReportingDisabled(e.target.checked);
          }
          if (e.target.name == "from_template") {
            let is_set = !(e.target.value == null || e.target.value == "");
            projectFields.reporting_from_template.value = is_set;
            this.setReportingDisabled(is_set);
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { companyId } = ownProps;
  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let projects = company.get(`projects`);
  // Get tracker templates
  let trackers = state.getInPath(`entities.trackers.byId`);
  let templates = trackers ? trackers.filter(item => item.get("is_template")) : null;
  let user = state.getInPath("auth.user");
  return { company, projects, templates, user };
};

export default connect(mapStateToProps)(CompanyProjectForm);
