import React, { useState, useEffect } from "react";
import Traec from "traec";

import { BSCard, BSBtn } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors/handleError";
import CreatableSelect from "react-select/creatable";

const fetchOptions = ({ fields }, setFieldOptions) => {
  let fetch = new Traec.Fetch("company_dispatch", "post");
  fetch.preDispatchHook = action => {
    //console.log("Calling dispatchFromProps preDispatchHook", action);
    action.fetchParams.body = {
      type: "COMMIT_META_FIELD_VALUES",
      payload: {
        fields
      }
    };
    action.stateParams.stateSetFunc = (state, data) => {
      //console.log("GOT COMMIT_META_FIELD_VALUES company_dispatch endpoint", data);
      setFieldOptions(Traec.Im.fromJS(data.payload.payload));
      return state;
    };
    //console.log("Calling company_dispatch API endpoint for COMMIT_META_FIELD_VALUES data", action);
    return action;
  };
  return fetch;
};

function FilterField({ header, aliases, value, options, onChangeHandler }) {
  let _header = aliases.get(header) || header;

  options = (options || Traec.Im.List())
    //.map(i => (i == null ? {value: "", label: "undefined"} : i)) // Get rid of the null values
    .filter(i => i)
    .toJS()
    .map(i => (typeof i === "string" ? { value: i, label: i } : i));

  let valueSet = new Traec.Im.Set(value || []);
  //console.log("RENDERING FieldMultiSelect", valueSet.toJS(), options);

  return (
    <ErrorBoundary>
      <div className="col-sm-4">
        <div className="form-group">
          <label>
            <b>{_header}</b>
          </label>
          <CreatableSelect
            isClearable={true}
            isMulti={true}
            placeholder="Select..."
            onChange={data => {
              let value = data ? data.map(i => i.value) : null;
              //console.log("Handling change to value", value, data);
              onChangeHandler({ [header]: Traec.Im.Set(value) });
            }}
            options={options}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

function DashboardFilters(props) {
  let { companyId, fields, onApply, appliedFilters } = props;
  if (!companyId || !fields) {
    return null;
  }

  // If the fields is a JSON string then we have to unpack it
  let _fields = null;
  if (typeof fields === "string") {
    _fields = Traec.Im.fromJS(JSON.parse(fields));
  } else {
    _fields = Traec.Im.fromJS(fields);
  }

  // Ensure we have something for the aliases
  let aliases = Traec.Im.Map();
  if (Traec.Im.isMap(_fields)) {
    aliases = Traec.Im.fromJS(_fields);
  }

  let [state, setState] = useState({});
  let [fieldOptions, setFieldOptions] = useState(Traec.Im.Map());
  let [values, setValues] = useState(Traec.Im.Map());

  //console.log("Got fieldOptions to render", fieldOptions?.toJS(), values?.toJS());

  useEffect(() => {
    // Do nothing if we don't have a companyId
    if (!companyId) {
      return null;
    }
    // Called fetchRequired
    Traec.fetchRequiredFor({
      props,
      state,
      setState,
      requiredFetches: [fetchOptions(props, setFieldOptions)]
    });
  });

  let filters = fieldOptions
    .map((options, header) => Traec.Im.Map({ options, header }))
    .toList()
    .map((item, i) => (
      <FilterField
        key={i}
        header={header}
        aliases={aliases}
        options={options}
        onChangeHandler={value => {
          setValues(values.merge(value));
        }}
      />
    ));

  // Apply the filters
  let isApplied = values.equals(appliedFilters);

  return (
    <BSCard
      widthOffset="col-sm-12"
      title="Data Filters"
      body={
        <ErrorBoundary>
          <div className="row">{filters}</div>
          {isApplied ? null : <BSBtn text="Apply" onClick={e => onApply(values)} />}
        </ErrorBoundary>
      }
    />
  );
}

export default DashboardFilters;
